import { isDonationFormEnabled } from "./utils";

(function(){
    document.addEventListener('alpine:init', () => {
        if (! isDonationFormEnabled()) {
            return;
        }

        Alpine.data('donationControls', () => {
            return {
                name: {error: false, blurred: false },
                email: {error: false, blurred: false },

                blurInput(event) {
                    let el = event.target;
                    this[el.name].blurred = true;

                    // just empty test
                    if (this.fieldsData[el.name].length === 0) {
                        this[el.name].error = true;
                        return;
                    }

                    if (el.name === 'name' &&  ! isTextValid(this.fieldsData['name'])) {
                        this[el.name].error = true;
                        return;
                    }

                    if (el.name === 'email' && ! isEmailValid(el.value)) {
                        this[el.name].error = true;
                        return;
                    }

                    this[el.name].error = false;
                },

                blurReset(event) {
                    let el = event.target;
                    this[el.name].error = false;
                    this[el.name].blurred = false;
                },
            }
        });
    });
})();
