import Cookies from 'js-cookie'


export function check_analytics_registration_cookie() {
    const cookie_name = 'fr_analytics_registration';

    const value = Cookies.get(cookie_name);

    if (value === 'yes') {
        try_send_GA_event('registration', {});
        Cookies.remove(cookie_name);
    }
}

/**
 * @param {bool} isOpened
 */
export function try_send_form_opened_GA_event(isOpened) {
    if (! isOpened) {
        return;
    }

    if (window.friendly?.isFormOpenedOnce) {
        return;
    }
    window.friendly.isFormOpenedOnce = true;

    try_send_GA_event('click_support', {});
}

export function try_send_GA_event(event_name, data) {
    console.log(`GA_event - ${event_name}: sending`);

    const rollbarAvailable = !! window?.Rollbar?.error;

    try {
        let msg = {};
        switch (event_name) {
            case 'checkout':
                msg = get_msg_for_GA_event_checkout(event_name, data);
                break;
            case 'registration':
                msg = {'event': 'registration'};
                break;
            case 'click_support':
                msg = {'event': 'click_support'};
                break;
            default:
                if (rollbarAvailable) {
                    Rollbar.error(`Unknown GA event name: ${event_name}`);
                }
                return;
        }

        console.log(`GA_event - ${event_name}: msg`, msg);

        if (! window?.dataLayer?.length) {
            window['dataLayer'] = window['dataLayer'] || [];

            // warn only on production, we don't load GA on env:development at least
            if (rollbarAvailable) {
                Rollbar.warning('GA is not initialized: no dataLayer in window');
            }
        }

        // Send msg
        dataLayer.push(msg);

    } catch (e) {
        // if Rollbar wasn't loaded, just continue,
        // it's not critical to lose analytics data in this place
        if (rollbarAvailable) {
            Rollbar.error("GA event sending failed", e);
        }
        console.error(e);
        console.info('GA_event - checkout: failed');
        return;
    }
    console.info(`GA_event - ${event_name}: sent`);
}


// DON'T use it directly in a business logic
// Used by try_send_GA_event() function
function get_msg_for_GA_event_checkout (event_name, data) {
    const err_tag = "[critical, analytics, donation]";

    const valid_period_types = ['single', 'recurring'];
    if (! valid_period_types.includes(data?.period)) {
        throw new Error(`${err_tag} Unknown period type: ${data?.period}`);
    }

    const valid_purpose_types = ['author', 'campaign'];
    if (! valid_purpose_types.includes(data?.purpose_type)) {
        throw new Error(`${err_tag} Unknown purpose type: ${data?.purpose_type}`);
    }

    if (! data?.amount) {
        throw new Error(`${err_tag} Invalid amount value: ${data?.amount}`);
    }

    return {
        'event': event_name,
        'PayPeriod': data.period,
        'Sum': data.amount,
        'PurposeType': data.purpose_type,
    };
}
