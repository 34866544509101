import { try_send_GA_event } from "../analytics/analytics";
import { getGAUserID, getYaMetrikaUserID } from "../analytics/utils";
import { isDonationFormEnabled } from "./utils";


(function(){
    const GATEWAY_YOOKASSA = 'yookassa';
    const GATEWAY_PAYSELECTION = 'payselection';
    const validGateways = [GATEWAY_YOOKASSA, GATEWAY_PAYSELECTION];

    const urlParams = new URLSearchParams(window.location.search);

    document.addEventListener('alpine:init', () => {
        if (! isDonationFormEnabled()) {
            return;
        }

        // The store and the component have the same name "donationForm"
        // pay attention to this fact if smth strange happens in the future
        Alpine.store('donationForm', {
            commission: null, // float, e.g. 0.035 - 3.5%, the processing commission
        });

        Alpine.store('fieldsErrors', {
            name: false,
            email: false,
            amount: false,
            agree: false,
        });

        Alpine.data('donationForm', () => {
            const dform_args = window.fr_dform_args;

            const target_type = dform_args.target_type;
            const is_preview = dform_args.is_preview;

            let default_payment_gateway_type = dform_args.default_payment_gateway_type;

            const forcedGateway = urlParams.get('default-gateway');
            if (validGateways.includes(forcedGateway)) {
                default_payment_gateway_type = forcedGateway;
            }

            const initial_period_type = target_type === 'author' ? dform_args.donation_default_period: 'single';

            const donation_creation_url = dform_args.donation_creation_url;

            const success_url = dform_args.success_url;

            const redirect_to_yookassa = (data, xform) => {
                console.log(data);
                console.log(xform);
                location.href = data.redirect_url;
            };

            const display_payselection_widget = (data, xform) => {
                console.log(data);
                console.log(xform);

                console.log('finished - widget');
            };

            const redirect_to_payselection = (data, xform) => {
                console.log(data);
                console.log(xform);

                location.href = data.payment_url;
            };

            return {
                test_flag: 'test_value', // for debugging
                test_function: function () {console.log('Hello');}, // for debugging
                lotSpec: {
                    small: dform_args.lots.small,
                    medium: dform_args.lots.medium,
                    large: dform_args.lots.large,
                    flex: dform_args.lots.flex,
                },
                fieldsData: {
                    gateway_type: default_payment_gateway_type,
                    period: initial_period_type,
                    name: '',
                    email: '',
                    amount: 0,
                    lot: 'unknown',  // TODO constant from python ???
                    is_foreign_card: false,
                    agree: false,
                },
                errors: {
                    validation: dform_args.error_messages.validation,
                    general: dform_args.error_messages.general,
                    cancel: dform_args.error_messages.cancel,
                    pm_error: dform_args.error_messages.pm_error,
                },
                amountSettings: dform_args.amount_settings,
                csrf_token: dform_args.csrf_token,
                loading: false, // form is submitting (AJAX HTTP request)
                payment_open: false, // "collapse" layout feature

                message: '', // a generic error msg (in the top of the form)

                is_yookassa() {
                    return this.fieldsData.gateway_type === GATEWAY_YOOKASSA;
                },
                is_payselection() {
                    return this.fieldsData.gateway_type === GATEWAY_PAYSELECTION;
                },

                init() {
                    this.$watch('fieldsData.is_foreign_card', () => this.onIsForeignCardChanged());
                    this.$store.donationForm.commission = dform_args.commission_compensation;
                },

                /**
                 * Should the form display a generic error message in the top of the form.
                 *
                 * @return {boolean}
                 */
                get showFormError() {
                    return this.message.length > 0;
                },

                /**
                 * @see https://stackoverflow.com/questions/33429136/round-to-3-decimal-points-in-javascript-jquery
                 * @return {number} In percents (float).
                 */
                get commissionVisible() {
                    const raw = this.$store.donationForm.commission * 100.0;
                    return parseFloat(raw.toFixed(2))
                },

                periodChange(event, $dispatch) {
                    const period = event.target.value;

                    this.$store.amountFixDefault.small = this.amountSettings[period]['small'];
                    this.$store.amountFixDefault.medium = this.amountSettings[period]['medium'];
                    this.$store.amountFixDefault.large = this.amountSettings[period]['large'];

                    $dispatch("update-amount-defaults");
                },

                changeGatewayTo(gateway_id) {
                    this.fieldsData.gateway_type = gateway_id;
                    this.$dispatch("gateway-changed");
                },

                onIsForeignCardChanged() {
                    const isForeign = this.fieldsData.is_foreign_card;

                    // force PaySelection for foreign cards
                    if (isForeign) {
                        this.changeGatewayTo(GATEWAY_PAYSELECTION);
                        return;
                    }

                    // if is not foreign card -> reset to a default gateway
                    if (this.fieldsData.gateway_type !== default_payment_gateway_type) {
                        this.changeGatewayTo(default_payment_gateway_type);
                    }
                },

                validateFields() {
                    let valid = true;

                    if (! isTextValid(this.fieldsData['name'])) {
                        valid = false;
                        this.$store.fieldsErrors.name = true;
                    }

                    if (! isEmailValid(this.fieldsData['email'])) {
                        valid = false;
                        this.$store.fieldsErrors.email = true;
                    }

                    if (! isAmountValid(this.fieldsData['amount'], this.$store.amountLimits)) {
                        valid = false;
                        this.$store.fieldsErrors.amount = true;
                    }

                    if (! this.fieldsData['agree']) {
                        valid = false;
                        this.$store.fieldsErrors.agree = true;
                    }

                    return valid;
                },

                submitForm() {
                    this.loading = true;
                    this.message = '';

                    // validation
                    const formValid = true; // this.validateFields();

                    if (! formValid) {
                        this.loading = false;
                        this.message = dform_args.message_pls_correct;
                        return;
                    }

                    // shortcut in "Preview mode"
                    if (is_preview) {
                        this.message = dform_args.message_preview;
                        this.loading = false;
                        return;
                    }

                    // build request body
                    const form = new FormData();

                    for (const field_name in this.fieldsData) {
                        form.append(field_name, this.fieldsData[field_name]);
                    }

                    // Add Google.Analytics User ID
                    form.append('ga_uid', getGAUserID());
                    form.append('ya_uid', getYaMetrikaUserID());

                    const that = this;

                    // try to save info about donation on backend
                    fetch(donation_creation_url, {
                        method: 'POST',
                        headers: {
                            'X-CSRFToken': dform_args.csrf_token,
                        },
                        body: form,
                    })
                    .then(response => response.json())
                    .then((data) => {
                        if (data.status === 'ok') {
                            that.handleFormResponse(data.payment_data);
                        } else if (data.status === 'invalid') {
                            const message = JSON.parse(data.message);
                            console.log('data.message parsed', message);

                            const errors = Object.keys(message);
                            if (errors) {
                                for (const field of errors) {
                                    that.$store.fieldsErrors[field] = true;
                                }
                            }
                            that.message = that.errors.validation;
                        } else {
                            console.log(data.message); // TODO handle the error
                            that.message = that.errors.general;
                        }
                    })
                    .catch((error) => {
                        console.log(error); // TODO handle the error
                        that.message = that.errors.general;
                    })
                    .finally(() => {
                        that.loading = false;
                    });
                },

                handleFormResponse(data) {
                    try_send_GA_event('checkout', {
                        period: this.fieldsData.period,
                        amount: this.fieldsData.amount,
                        purpose_type: target_type === 'author' ? 'author' : 'campaign',
                    });

                    if (this.is_yookassa()) {
                        redirect_to_yookassa(data, this);
                    } else if (this.is_payselection()) {
                        // display_payselection_widget(data, this);
                        redirect_to_payselection(data, this);
                    } else {
                        throw new Error('not implemented');
                    }
                },
            };
        });

    });

})();
