export function isEmailValid(string) {
    var patt = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return patt.test(string);
}

export function isTextValid(string) {
    if (string.length > 100) {
        return false;
    }

    if (string.length < 2) {
        return false;
    }

    if (! string.match(/^[-а-яА-ЯёЁa-zA-Z0-9\s.,]+$/g)) {
        return false;
    }

    return true;
}

export function isAmountValid(amount, amountLimits) {
    const amount_number = parseInt(amount);
    return amount_number >= amountLimits.min;
}
