import Cookies from "js-cookie";

/**
 * @returns {string} Example: "299045626.1663786130"
 *
 * @see https://stackoverflow.com/questions/20053949/how-to-get-the-google-analytics-client-id
 */
export function getGAUserID() {
    const default_id = '1234567890.1234567890';

    const real_id = getGtmClientId();

    if (! real_id) {
        console.warn('Skipped GA UID');
        return default_id;
    }

    return real_id;
}

/**
 * Use only with gtm.js (GTM) loader
 *
 * @return {string}
 */
function getGtmClientId() {
    const raw_value = Cookies.get('_ga'); // string; example: GA1.1.299045626.1663786130
    if (! raw_value || ! raw_value.length) {
        return '';
    }

    return raw_value.slice(6); // cut "GA1.1." part
}

/**
 * Use only with gtag.js (GA4) loader
 *
 * @return {string}
 */
function getGtagClientId() {
    if (! window?.ga?.getAll || ! ga?.length) {
        return '';
    }

    return ga.getAll()[0].get('clientId');
}

/**
 * Works only with donation form for now (see "fr_dform_args.ym_id" check)
 *
 * @return {string} Example: "1663786130600472854"
 *
 * @see https://yandex.ru/support/metrica/objects/get-client-id.html
 */
export function getYaMetrikaUserID() {
    if (! window?.ym) {
        console.warn('Skipped YA UID - JS object not found');
        return '';
    }

    const counter_id = window?.fr_dform_args?.ym_id;
    if (! counter_id) {
        console.warn('Skipped YA UID - ym_id not set');
        return '';
    }

    let clid = '';

    ym(counter_id, 'getClientID', function(clientID) {
      clid = clientID;
    });

    return clid;
}
