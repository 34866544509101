/**
 * Scroll bar width
 */

export function initScrollWidthControl() {
    document.addEventListener("DOMContentLoaded", function(event) {
        getScrollBarWidth();
    });

    document.addEventListener("resize", function(event) {
        getScrollBarWidth();
    });
}

function getScrollBarWidth() {
    var scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.documentElement.style.setProperty('--scroll-bar-width', scrollbarWidth + 'px');
}
